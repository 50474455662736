import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/reset.css";
import "./assets/css/border.css";
import "amfe-flexible";
Vue.config.productionTip = false;
Vue.config.ignoredElements = ["wx-open-launch-weapp"];
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
