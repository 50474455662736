import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/", //首页 index
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  // {
  //   path: "/details", //答题页
  //   name: "Details",
  //   component: () => import("../views/Details.vue"),
  // },
  // {
  //   path: "/recorder", //录音测试页
  //   name: "Recorder",
  //   component: () => import("../views/Recorder.vue"),
  // },
  {
    path: "/result", //结果页
    name: "Result",
    component: () => import("../views/Result.vue"),
  },
  {
    path: "/answer", //测试页   answer
    name: "Answer",
    component: () => import("../views/Answer.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
